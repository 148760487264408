// import "./about.scss";
import Swiper from "swiper/bundle";

/**
 * Swiper
 * Add carousel to mutliple areas
 */

// Posts (Case Studies)
// var swiper = new Swiper(".post-swiper", {
//     slidesPerView: 1,
//     spaceBetween: 30,
//     pagination: {
//         el: ".swiper-pagination-post",
//         clickable: true,
//     },
//     navigation: {
//         nextEl: ".swiper-button-next-custom-post",
//         prevEl: ".swiper-button-prev-custom-post",
//     },
//     breakpoints: {
//         // when window width is >= 640px
//         640: {
//             slidesPerView: 3,
//             spaceBetween: 30,
//         }
//       }
// });
